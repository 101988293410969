.react-datepicker {
  font-size: 1em;
}
.react-datepicker__input-container input {
  text-align: center;
  width: 120px;
  color: black;
}
.react-datepicker__header {
  padding-top: 0.8em;
}
.react-datepicker__month {
  margin: 0.4em 1em;
}
.react-datepicker__day-name,
.react-datepicker__day {
  width: 1.9em;
  line-height: 1.9em;
  margin: 0.166em;
}
.react-datepicker__current-month {
  font-size: 1em;
}
.react-datepicker__navigation {
  top: 1em;
  line-height: 1.7em;
  border: 0.45em solid transparent;
}
.react-datepicker__navigation--previous {
  border-right-color: #ccc;
  left: 1em;
}
.react-datepicker__navigation--next {
  border-left-color: #ccc;
  right: 1em;
}

.my-inspector-table .rt-tr-group {
  max-height: 25px;
  overflow: hidden;
}

table.dataTable tbody th,
table.dataTable tbody td {
  padding: 3px 10px; /* e.g. change 8x to 4px here */
}

.log-div {
  background: #001628;
  color: white;
  height: 100%;
  overflow: auto;
  border-radius: 10px;
  height: 380px;
  padding: 16px;
}

.title-chart {
  font-weight: bold;
  font-size: 14px;
}

.my-inspector-table .rt-tbody {
  height:200px;
  justify-content: flex-start;
  flex: 1 1 auto;
  position: relative; /* need this to position inner content */
  overflow-y: auto;
}
.my-inspector-table .rt-tr-group {
  min-height:25px
}
.my-inspector-table .rt-thead {
  flex: 0 0 auto;
}
